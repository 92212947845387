import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Countdown from '../components/Countdown'
import Loading from '../components/Loading'
import Question from '../components/Question'
import RacingGame from '../components/RacingGame'
import RoomInactive from '../components/Room/Inactive'
import WaitingRoom from '../components/WaitingRoom'
import { subscribeToGameChanges } from '../redux/modules/game/actions'
import { subscribeToRoomChanges, subscribeToRoomIndexUsersChanges, subscribeToRoomOwnerChanges } from '../redux/modules/room/actions'
import { subscribeToTrophyChanges } from '../redux/modules/trophy/actions'
import { setSection } from '../redux/modules/ui/reducer'
import { useAppDispatch, useAppSelector } from '../redux/store'
import LeaderBoard from '../components/LeaderBoard'
import SentryErrorBoundary from '../components/SentryErrorBoundary'

const HomePage = () => {

  const dispatch = useAppDispatch()
  const { room, owner } = useAppSelector(state => state.room)
  const { section } = useAppSelector(state => state.ui)
  const { roomId } = useParams()

  useEffect(() => {

    const unsubscribeRoom = dispatch(
      subscribeToRoomChanges(String(roomId))
    )
    const unsubscribeIndexUsers = dispatch(
      subscribeToRoomIndexUsersChanges(String(roomId))
    )

    setTimeout(() => {
      dispatch(setSection('waiting-room'))
    }, 1000)

    return () => {
      unsubscribeRoom()
      unsubscribeIndexUsers()
    };

  }, [])

  useEffect(() => {

    if (!room?.game) return

    const unsubscribe = dispatch(subscribeToGameChanges(room.game))

    return () => {
      unsubscribe()
    };

  }, [room?.game])

  useEffect(() => {

    if (!room.owner) return

    const unsubscribe = dispatch(subscribeToRoomOwnerChanges(room.owner))

    return () => {
      unsubscribe()
    };

  }, [room?.owner])

  useEffect(() => {

    if (!owner?.trophy) return

    const unsubscribe = dispatch(subscribeToTrophyChanges(owner.trophy))

    return () => {
      unsubscribe()
    };

  }, [owner?.trophy])

  if (!room) return <Loading />

  if (section === 'loading') return <Loading />

  if (section === 'countdown') return <SentryErrorBoundary><Countdown /></SentryErrorBoundary>
  if (section === 'question') return <SentryErrorBoundary><Question /></SentryErrorBoundary>
  if (section === 'racing-game' || section === 'leader-board-final'
    || section === 'finish-game') return <SentryErrorBoundary> <RacingGame /></SentryErrorBoundary>
  if (section === 'leader-board') return <SentryErrorBoundary><LeaderBoard /></SentryErrorBoundary>
  // if (section === 'leader-board-final') return <LeaderBoardFinal />
  // if(section==='finish-game') return <Winner />

  if (!room.active) return <RoomInactive />

  return (
    <WaitingRoom />
  )
}

export default HomePage