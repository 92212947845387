import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import './index.css'
import { makeStore } from './redux/store.ts'
import { router } from './router.tsx'
import * as Sentry from "@sentry/react";

// ReactDOM.createRoot(document.getElementById('root')!).render(
//   <React.StrictMode>
//     <Provider store={makeStore()}>
//       <RouterProvider router={router} />
//     </Provider>
//   </React.StrictMode>,
// )

Sentry.init({
  dsn: "https://0f97b596e38be2d0b1d5f694a5e8c500@o4507550808080384.ingest.us.sentry.io/4507572826996736",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={makeStore()}>
    <RouterProvider router={router} />
    <div id="message"></div>
    {/* <div id="line"></div> */}
  </Provider>
)
